<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card
            no-body
            class="m-0"
          >
            <b-card-header>
              <b-card-title>
                PHÂN QUYỀN HỆ THỐNG
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <b-form
                  ref="form-phan-quyen"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <b-row>
                    <b-col
                      md="3"
                      cols="12"
                    >
                      <!-- Nhóm chức năng -->
                      <validation-provider
                        #default="validationContext"
                        name="Nhóm chức năng"
                      >
                        <b-form-group
                          label="Nhóm chức năng"
                          label-for="nhom-chuc-nang"
                        >
                          <v-select
                            v-model="chucNangSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="optionsNhomChucNang"
                            :clearable="false"
                            input-id="nhom-chuc-nang"
                            :state="getValidationState(validationContext)"
                            @input="changeNhomChucNang"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <table class="table-bordered table">
                    <thead>
                      <tr>
                        <th
                          v-for="item in thead"
                          :key="item.key"
                          class="text-center"
                          :width="item.width"
                        >
                          {{ item.label }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in items"
                        :key="item.chuc_nang_id"
                      >
                        <td>{{ item.chuc_nang }}</td>
                        <td
                          v-for="(itemTd, indexTd) in item.chuc_nang_phan_quyen"
                          :key="itemTd.vai_tro_id"
                        >
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <b-form-checkbox
                              v-model="boxChecked.matrix[index][indexTd]"
                              unchecked-value=""
                              :value="phanQuyenSelected[index][indexTd]"
                              class="checkbox-dark checkbox text-center"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <feather-icon
                        icon="CloudIcon"
                        class="mr-50"
                      />
                      Lưu lại
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>

</template>

<script>

import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BRow,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, ref } from '@vue/composition-api'
import phanQuyenStoreModule from '@/views/phan-quyen/phanQuyenStoreModule'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BRow,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showBlock: false,
      thead: [],
      chucNangSelected: { key: 'Chọn', label: '-- Chọn --' },
      boxChecked: {
        matrix: [],
      },
      items: [],
      phanQuyenSelected: [],
    }
  },
  methods: {
    onSubmit() {
      const dataUser = getUserData()

      store.state.showBlock = true
      store
        .dispatch('app-phan-quyen/save', {
          auth: dataUser.auth_key,
          uid: dataUser.id,
          checked: this.boxChecked,
          nhom_chuc_nang: this.chucNangSelected,
        })
        .then(response => {
          store.state.showBlock = false
          this.showToast('success', 'Thông báo', 'BellIcon', response.data.message)
        })
        .catch(() => {
          store.state.showBlock = false
        })
    },
    changeNhomChucNang() {
      const currentUser = getUserData()
      store.state.showBlock = true
      store
        .dispatch('app-phan-quyen/getPhanQuyen', {
          auth: currentUser.auth_key,
          uid: currentUser.id,
          nhom_chuc_nang: this.chucNangSelected,
        })
        .then(response => {
          this.thead = response.data.fields
          this.phanQuyenSelected = response.data.maTrixPhanQuyen
          this.boxChecked.matrix = response.data.checked
          // this.phanQuyenSelected = response.data.phanQuyenMatrix;
          this.items = response.data.phanQuyenMatrix
          store.state.showBlock = false
        })
        .catch(() => {
          store.state.showBlock = false
        })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-phan-quyen'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, phanQuyenStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const optionsNhomChucNang = ref([])
    const currentUser = getUserData()

    const resetuserData = () => {
    }

    store.state.showBlock = true
    store
      .dispatch('app-phan-quyen/getNhomChucNang', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        thong_ke: true,
      })
      .then(response => {
        optionsNhomChucNang.value = response.data.optionsNhomChucNang
        store.state.showBlock = false
      })
      .catch(() => {
        store.state.showBlock = false
      })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      optionsNhomChucNang,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>
