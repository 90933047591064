import axios from '@axios'
import config from '../../config.json'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getNhomChucNang(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}chuc-nang/get-nhom-chuc-nang`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPhanQuyen(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}phan-quyen/get-phan-quyen`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}phan-quyen/save`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
